import React, { Fragment, ReactElement, useEffect, useState } from "react"
import { useLazyQuery } from "@apollo/client"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import moment from "moment"
import { Dropdown, Loader, Menu, Popup } from "semantic-ui-react"
import Skeleton from "react-loading-skeleton"

import { AppointmentHandler } from "components/Appointment/AppointmentHandler"
import { CopyIcon } from "components/Icons/CopyIcon"
import ParcelStatusChip from "components/Parcels/ParcelStatus"
import NoteHandler from "components/Notes/NoteHandler"
import NoteHandlerSkeleton from "components/Notes/NoteHandlerSkeleton"
import ParcelInfosSkeleton from "components/Parcels/ParcelSkeleton/ParcelInfosSkeleton"
import { ParcelRecipient } from "components/Parcels/ParcelRecipient"
import ParcelPartner from "components/Parcels/ParcelPartner/ParcelPartner"
import { createParcelNoWithAdditionalInfo } from "components/Parcels/createParcelNoWithAdditionalInfo.util"
import {
  ParcelHeaderContainer,
  ParcelInfosContainer,
  ParcelInfosTitle,
  ParcelPartners,
} from "components/Parcels/ParcelInfos.styled"
import ParcelOrderInfos from "components/Parcels/ParcelOrderInfos/ParcelOrderInfos"
import { AdminParcelEventType, getParcelActions } from "modules/Ops/Parcel/getParcelActions"
import ParcelKeeper from "modules/Ops/Parcel/ParcelKeeper"
import AdminEventModal from "modules/Ops/Parcel/modals/AdminEventModal"
import AdminReassignModal from "modules/Ops/Parcel/modals/AdminReassignModal"
import {
  apiCreateParcelFlag,
  apiCreateParcelNote,
  apiGetAvailableCollectionCarriers,
  apiGetParcelEvents,
  apiGetParcelInfos,
  apiParcelAskReturn,
  apiParcelReturnCompleted,
  apiRemoveParcelFlag,
  apiSendParcelAdminEvent,
  apiUpdateParcelNote,
  resetFocusParcel,
} from "services/parcels"
import { getParcelKeeper, getReturnRequestDetails } from "services/graphql/queries/parcel.queries"
import { MeetingType } from "types/meeting.types"
import {
  AdminParcelEventReason,
  AskReturnParcelInput,
  GetParcelKeeperQuery,
  GetParcelReturnRequestQuery,
  Parcel,
  ParcelFlag,
  ParcelInfos as ParcelsInfosProps,
  ParcelNote,
  ParcelStatus,
} from "types/parcel.types"
import { ReduxState } from "types/reduxState.types"
import { Shipment, ShipmentType } from "types/shipment.types"
import { displayParcelFlag } from "utils/displayParcelFlag"
import { COLOR } from "utils/color"
import { ParcelBilling } from "components/ParcelBilling/ParcelBilling"
import AdminRequestModal from "./modals/ReturnRequestModal/ReturnRequestModal"
import { RecipientIdCard } from "../../../components/RecipientIdCard/RecipientIdCard"
import { BlockRow, BlockRowItem, BlockRowItemGap, QRCodeIcon } from "./ParcelHeader.styled"
import QRCode from "react-qr-code"
import { CarriersName } from "types/carrier.types"
import { ReturnToPlanModal } from "./modals/ReturnToPlanModal/ReturnToPlanModal"

const ParcelHeader = (): ReactElement => {
  const dispatch = useDispatch()
  const history = useParams()

  const parcels: ParcelsInfosProps = useSelector((state: ReduxState) => state.parcels)
  const { role } = useSelector((state: ReduxState) => state.auth)

  const [isParcelNoCopied, setParcelNoCopied] = useState(false)
  const [isReturRequestModalOpened, setIsReturRequestModalOpened] = useState(false)
  const [isParcelAlreadyCollected, setIsParcelAlreadyCollected] = useState(false)
  const [actionMenuValue, setActionMenuValue] = useState(null)
  const [isReturnToPlanOpened, setIsReturnToPlanOpened] = useState(false)
  const [adminEventModal, setAdminEventModal] = useState<{
    opened: boolean
    type: AdminParcelEventType | null
    reason: { value: string; label: string }
    details: string | null
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data?: any
  }>({
    opened: false,
    type: null,
    reason: null,
    details: null,
    data: {},
  })
  const [adminReassignModal, setAdminReassignModal] = useState<{
    opened: boolean
    parcel: Parcel | null
    shipment: Shipment | null
    carrierName: string | null
    currentKeeperInfos: {
      name: string
      address: string
    } | null
    newKeeperInfos: {
      name: string
      address: string
      instructions: string
      phone: string
    } | null
  }>({
    opened: false,
    parcel: null,
    shipment: null,
    carrierName: null,
    currentKeeperInfos: null,
    newKeeperInfos: null,
  })
  const [collectionCarriers, setCollectionCarriers] = useState({ loaded: false, data: [] })

  const [getParcelKeeperQuery, { data: parcelKeeperData, loading: parcelKeeperLoading }] =
    useLazyQuery<GetParcelKeeperQuery>(getParcelKeeper)

  const [getParcelReturnRequest, { data: parcelReturnRequestData }] = useLazyQuery<GetParcelReturnRequestQuery>(
    getReturnRequestDetails,
    { fetchPolicy: "network-only" },
  )

  const getParcelInfo = () => {
    const { id: parcelId } = history
    dispatch(apiGetParcelInfos(parcelId))
    getParcelKeeperQuery({ variables: { id: parcelId } })
  }

  useEffect(() => {
    getParcelInfo()
  }, [])

  /** Reset parcel data if params changed */
  useEffect(() => {
    const { id: parcelId } = history
    dispatch(resetFocusParcel())
    dispatch(apiGetParcelInfos(parcelId))
    getParcelKeeperQuery({ variables: { id: parcelId } })
  }, [history.id])

  // ************
  // ADMIN EVENTS
  const _openAdminEventModal = async (type: AdminParcelEventType) => {
    if (type === AdminParcelEventType.ASK_RETURN) {
      const r = await dispatch(apiGetAvailableCollectionCarriers(history.id))
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const data = r?.payload?.data

      if (!!data) setCollectionCarriers({ loaded: true, data: data })
    }

    setActionMenuValue(null)
    setAdminEventModal(prevState => ({
      opened: true,
      type,
      reason: null,
      details: null,
      data: prevState?.data,
    }))
  }

  const _openReturnToPlanModal = async (type: AdminParcelEventType) => {
    setAdminEventModal(prevState => ({
      opened: false,
      type,
      reason: null,
      details: null,
      data: prevState?.data,
    }))
    setIsReturnToPlanOpened(true)
  }

  const _cancelReturnToPlanModal = () => {
    setIsReturnToPlanOpened(false)
    setAdminEventModal({ type: null, reason: null, details: null, opened: false, data: {} })
  }

  const _changeReason = reason => {
    setAdminEventModal(prevState => ({ ...prevState, reason }))
  }

  const _changeDetails = details => {
    setAdminEventModal(prevState => ({ ...prevState, details }))
  }

  const _changeData = (key, value) => {
    setAdminEventModal(prevState => ({ ...prevState, data: { ...prevState.data, [key]: value } }))
  }

  const _cancelAdminEventModal = () => {
    setAdminEventModal({ type: null, reason: null, details: null, opened: false, data: {} })
  }

  const _submitAdminEvent = async (reason?: AdminParcelEventReason) => {
    setAdminReassignModal(prevState => ({ ...prevState, opened: false }))
    setAdminEventModal(prevState => ({ ...prevState, opened: false }))
    const parcelId = parcel.id

    // Handle non-standard admin events (ADD_FLAG, REMOVE_FLAG, ASK_RETURN, RETURN_COMPLETED)
    if (adminEventModal.type === AdminParcelEventType.ADD_FLAG) {
      dispatch(apiCreateParcelFlag(parcelId, { flag: ParcelFlag[adminEventModal.reason?.value] }))

      /** Clean state after the operation */
      setAdminEventModal(prevState => ({ ...prevState, reason: null, type: null }))
    } else if (adminEventModal.type === AdminParcelEventType.REMOVE_FLAG) {
      dispatch(apiRemoveParcelFlag(parcelId))
    } else if (adminEventModal.type === AdminParcelEventType.ASK_RETURN) {
      const data = {
        reason: isParcelAlreadyCollected
          ? AdminParcelEventReason.ALREADY_COLLECTED_BY_CARRIER
          : AdminParcelEventReason[adminEventModal.reason?.value],
        parcelId: parcelId,
        carrierId: adminEventModal.data.carrier.value,
        details: adminEventModal.details,
        meetingSlot: null, // when alreadyCollected: true
        cotransportationCode: adminEventModal.data?.cotransportationCode || null,
        referenceCode: adminEventModal.data?.referenceCode || null,
        alreadyCollected: isParcelAlreadyCollected,
      }

      // when alreadyCollected: false
      if (!isParcelAlreadyCollected) {
        if (adminEventModal.data.carrier.data.name !== CarriersName.POST_OFFICE && adminEventModal.data.carrier.data.name !== CarriersName.RELAISCOLIS) {
          data.meetingSlot = {
            eventStart: new Date(`${adminEventModal.data.date}T${adminEventModal.data.meetingSlotStart.value}`),
            eventEnd: new Date(`${adminEventModal.data.date}T${adminEventModal.data.meetingSlotEnd.value}`),
          }
        }
      }

      askReturn(data)
    } else if (adminEventModal.type === AdminParcelEventType.RETURN_COMPLETED) {
      let reason

      if (
        returningShipment?.__carrier__?.name === CarriersName.POST_OFFICE &&
        parcel.status === ParcelStatus.RETURNING
      ) {
        reason = AdminParcelEventReason.RETURNED_TO_BP
      }

      returnCompleted(parcelId, reason)
    } else {
      // It's a standard admin event
      const data = {
        parcelId: parcelId,
        event: {
          type: adminEventModal.type,
          reason: reason || adminEventModal.reason?.value,
          details: adminEventModal.details,
          data: adminEventModal.data,
        },
      }

      if (
        adminEventModal.type === AdminParcelEventType.ADMIN_RETURNING_SUCCESS &&
        returningShipment?.__carrier__?.name === CarriersName.POST_OFFICE
      ) {
        data.event.reason = AdminParcelEventReason.RETURNED_TO_BP
      }

      await dispatch(apiSendParcelAdminEvent(data))
      setAdminEventModal({ type: null, reason: null, details: null, opened: false, data: {} })
    }

    setTimeout(() => {
      getParcelKeeperQuery({ variables: { id: parcelId }, fetchPolicy: "network-only" })
    }, 300)

    if (adminEventModal.type === "REASSIGNMENT") {
      if (adminReassignModal.carrierName === "DHL") {
        setAdminReassignModal(state => ({ ...state, helpModalOpened: true }))
      }
    }
  }

  const askReturn = async (askReturnParcelInput: AskReturnParcelInput) => {
    await dispatch(apiParcelAskReturn(askReturnParcelInput))

    dispatch(apiGetParcelInfos(askReturnParcelInput.parcelId))
    dispatch(apiGetParcelEvents(askReturnParcelInput.parcelId))
  }

  const returnCompleted = async (parcelId, reason) => {
    await dispatch(apiParcelReturnCompleted({ id: parcelId, reason }))

    dispatch(apiGetParcelInfos(parcelId))
    dispatch(apiGetParcelEvents(parcelId))
  }

  // ************
  // UTILS / COPY FUNCTIONS

  const _copyParcelNo = (parcel: Parcel) => {
    const value = createParcelNoWithAdditionalInfo(parcel)

    setParcelNoCopied(true)
    // eslint-disable-next-line no-undef
    navigator.clipboard.writeText(value)

    setTimeout(() => {
      setParcelNoCopied(false)
    }, 1000)
  }

  const _getQRCodeValue = (parcelNo: string, carrierName: string): string => {
    if (carrierName === "GLS") {
      return `AFR0031FR007525000113292501369229${parcelNo}BBn         0FR2313975012  002000010011700703989370000FR    1700703989370000FR`
    }

    return parcelNo
  }

  // ************
  // NOTES
  const _createParcelNote = async ({ text, zendeskLink }) => {
    const parcelId = parcel?.id

    await dispatch(
      apiCreateParcelNote({
        parcelId,
        text,
        zendeskLink,
      }),
    )
  }

  const _updateParcelNote =
    note =>
    ({ text, zendeskLink }) => {
      dispatch(apiUpdateParcelNote(note.id, { text, zendeskLink }))
    }

  // ************
  // REASSIGN

  const _cancelAdminReassignModal = () => {
    setAdminReassignModal(prevState => ({ ...prevState, opened: false, parcel: null }))
  }

  const _openAdminReassignModal = (parcel, deliveryShipment) => {
    const carrierName = deliveryShipment?.__carrier__?.name
    // Basic Keeper data formatted
    const keeperName = `Keeper : ${parcelKeeperData.adminParcelKeeper.keeper.firstName} ${parcelKeeperData.adminParcelKeeper.keeper.lastName}`
    const formattedAddress = `Adresse : ${address.streetLine} ${address.zipcode} ${address.city}`

    setActionMenuValue(null)
    setAdminReassignModal({
      opened: true,
      parcel: parcel,
      shipment: deliveryShipment,
      carrierName: carrierName,
      currentKeeperInfos: {
        name: keeperName,
        address: formattedAddress,
      },
      newKeeperInfos: null,
    })
  }

  // ************

  const { infos } = parcels.focus
  const { data: dataReassign, loading: loadingReassign, pageInfos } = parcels.reassign
  const { data: parcel, loading } = infos

  useEffect(() => {
    if (parcel.id !== undefined && parcel.status === ParcelStatus.RETURN_TO_PLAN) {
      getParcelReturnRequest({ variables: { parcelId: parcel.id } })
    }
  }, [parcel])

  const note: ParcelNote | undefined =
    parcel?.__notes__?.length > 0
      ? // Spread array to avoid frozen array and errors
        [...parcel?.__notes__].sort((a, b) => moment(b?.updatedAt).diff(moment(a?.updatedAt)))[0]
      : undefined

  const deliveryShipment = parcel.__shipments__?.find(shipment =>
    [ShipmentType.DELIVERY_BTOC, ShipmentType.DELIVERY].includes(shipment.type),
  )

  const returningShipment = parcel.__shipments__?.find(i =>
    [ShipmentType.RETURN_BTOC, ShipmentType.RETURN].includes(i.type),
  )

  const dropoffShipment = parcel.__shipments__?.find(i => i.type === ShipmentType.DELIVERY_CTOB)
  const recipient = parcel?.order?.recipient
  const address = parcel?.order?.__address__

  const billingEntries = parcel.companyBillingEntries
  const walletEntries = parcel.walletEntries

  const companyAddress = deliveryShipment?.__carrierDeliveryHub__

  const orderId = parcel?.order?.id
  const orderType = parcel?.order?.type

  // Need to slice to unfroze the array
  const meetings = parcel?.order?.__meetings__
    ?.slice()
    .filter(meeting => meeting.cancelled === false)
    .sort((a, b) => moment(a.eventStart).diff(moment(b.eventStart)))

  const PARCEL_ACTIONS = getParcelActions(parcel, deliveryShipment?.__carrier__?.name, role, orderType)

  const getParcelShipmentsMeta = () => {
    const meta = returningShipment?.picking?.parcelShipmentMeta

    return meta?.find && meta.find(mt => mt.cotransportationCode || mt.referenceCode)
  }

  const getParcelReturnFormattedTime = () => {
    if (!parcelReturnRequestData?.getReturnRequestDetails?.returnBtocRequest) return ""

    const { interval, pickupDate } = parcelReturnRequestData.getReturnRequestDetails.returnBtocRequest
    const startDate = moment.utc(pickupDate, "YYYY-MM-DD").format("DD-MM").replace("-", "/")
    const timeFormat = "HH[h]"

    return `${startDate} entre ${moment(interval?.start).format(timeFormat)} et ${moment(interval?.end).format(
      timeFormat,
    )}`
  }

  return (
    <Fragment>
      <ParcelHeaderContainer>
        <BlockRow>
          {parcel.id === undefined ? (
            <ParcelInfosSkeleton />
          ) : (
            <ParcelInfosContainer>
              <ParcelInfosTitle
                style={{
                  color:
                    parcel?.flag !== null
                      ? COLOR.DARK_RED
                      : parcel.archived
                      ? COLOR.MEDIUM_SUPER_LIGHT_GREY
                      : COLOR.DARK_BLUE,
                }}
              >
                <Popup
                  content={isParcelNoCopied ? "Copié ✅" : `Copier le numéro de colis`}
                  size="small"
                  position="bottom right"
                  trigger={
                    <span onClick={() => _copyParcelNo(parcel)} style={{ cursor: "pointer" }}>
                      <span>{parcel?.parcelNo || <Skeleton width={"50%"} />}</span>
                      <span style={{ fontSize: 24 }}>
                        <CopyIcon />
                      </span>
                    </span>
                  }
                />
                {process.env.REACT_APP_STAGE !== "production" && (
                  <Popup
                    content={
                      <QRCode
                        value={_getQRCodeValue(parcel?.parcelNo, deliveryShipment?.__carrier__?.name)}
                        size={128}
                      />
                    }
                    size="small"
                    position="bottom center"
                    trigger={
                      <span>
                        <QRCodeIcon />
                      </span>
                    }
                  />
                )}
              </ParcelInfosTitle>
              {parcel?.flag !== null && (
                <div style={{ color: COLOR.DARK_RED, marginBottom: 10 }}>🚩 {displayParcelFlag(parcel?.flag)}</div>
              )}
              {parcel?.archived && <div style={{ marginBottom: 10 }}>🗂 Colis archivé</div>}

              {loading ? <Loader active inline /> : <ParcelStatusChip status={parcel?.status} />}

              <ParcelOrderInfos
                orderNo={parcel?.order?.orderNo}
                orderStatus={parcel?.order?.status}
                orderType={parcel?.order?.type}
                deliveryCode={parcel?.order?.deliveryCode}
                shipper={parcel?.order?.shipperName}
                maxHoldingDate={parcel?.maxHoldingDate}
              />

              <ParcelPartners>
                {deliveryShipment !== undefined && (
                  <ParcelPartner
                    emoji="🚛"
                    type="Expédition (Aller)"
                    partnerName={deliveryShipment.__carrier__?.name}
                    status={deliveryShipment.status}
                    companyAddress={companyAddress}
                    shipmentNo={deliveryShipment.shipmentNo}
                    parcelNo={parcel.parcelNo}
                    keeperAddress={address}
                    estimatedDeliveryDate={deliveryShipment.announcedDeliveryDate}
                  />
                )}
                {returningShipment !== undefined && (
                  <ParcelPartner
                    emoji="🚛"
                    type="Expédition (Retour)"
                    partnerName={returningShipment.__carrier__?.name}
                    status={returningShipment.status}
                    shipmentNo={returningShipment.shipmentNo}
                    parcelNo={parcel.parcelNo}
                    meeting={meetings.find(meeting => meeting.type === MeetingType.COLLECT)}
                    keeperAddress={address}
                    shipmentsMeta={getParcelShipmentsMeta()}
                  />
                )}
                {dropoffShipment !== undefined && (
                  <ParcelPartner
                    emoji="🚛"
                    type="Expédition (Dropoff)"
                    partnerName={dropoffShipment.__carrier__?.name}
                    status={dropoffShipment.status}
                    shipmentNo={dropoffShipment.shipmentNo}
                    parcelNo={parcel.parcelNo}
                    meeting={meetings.find(meeting => meeting.type === MeetingType.DROPOFF)}
                    keeperAddress={address}
                  />
                )}
                {!!parcelReturnRequestData?.getReturnRequestDetails?.returnBtocRequest &&
                  parcel.status === ParcelStatus.RETURN_TO_PLAN && (
                    <ParcelPartner
                      emoji="🚛"
                      type="Demande de retour a valider"
                      partnerName={""}
                      status={""}
                      companyAddress={companyAddress}
                      shipmentNo={getParcelReturnFormattedTime()}
                      parcelNo={"Rendez-vous"}
                      keeperAddress={address}
                      estimatedDeliveryDate={"date"}
                      onVerify={() => setIsReturRequestModalOpened(true)}
                    />
                  )}
              </ParcelPartners>
            </ParcelInfosContainer>
          )}

          <ParcelKeeper parcelKeeper={parcelKeeperData?.adminParcelKeeper} loading={parcelKeeperLoading} />

          <ParcelRecipient orderId={orderId} recipient={recipient} loading={parcel.id === undefined} />
        </BlockRow>
        <BlockRow>
          <BlockRowItemGap>
            <ParcelBilling
              companyBillingEntries={billingEntries}
              walletEntries={walletEntries}
              idsData={{
                walletId: parcelKeeperData?.adminParcelKeeper?.keeper?.walletId,
                parcelId: parcel.id,
                companyId: parcel.order.clientId,
              }}
            />
            <AppointmentHandler orderId={orderId} />
          </BlockRowItemGap>
          <BlockRowItem>
            {parcel.id === undefined ? (
              <NoteHandlerSkeleton />
            ) : (
              <NoteHandler note={note} createNote={_createParcelNote} updateNote={_updateParcelNote(note)} />
            )}
          </BlockRowItem>
        </BlockRow>
        <BlockRow>
          <BlockRowItem>
            <RecipientIdCard parcel={parcel} />
          </BlockRowItem>
        </BlockRow>
      </ParcelHeaderContainer>

      <div className="parcel-content">
        <div className="actions-buttons">
          {!parcel?.archived && PARCEL_ACTIONS?.filter(i => i.active === true).length > 0 && (
            <Menu compact>
              <Dropdown
                text="Actions"
                direction="left"
                className={"dropdown-menu-text"}
                options={PARCEL_ACTIONS.filter(i => i.active === true).map(i => ({
                  key: i.key,
                  text: i.text,
                  value: i.value,
                }))}
                value={actionMenuValue}
                onChange={(e, item) => {
                  const value = item.value as AdminParcelEventType

                  if (value === AdminParcelEventType.REASSIGNMENT) {
                    _openAdminReassignModal(parcel, deliveryShipment)
                    return
                  } else if (value === AdminParcelEventType.RETURN_TO_PLAN) {
                    _openReturnToPlanModal(AdminParcelEventType.RETURN_TO_PLAN)
                    return
                  }

                  _openAdminEventModal(value)
                }}
                simple
                item
              />
            </Menu>
          )}
        </div>
      </div>
      <AdminEventModal
        adminEventModal={adminEventModal}
        cancelAdminEventModal={_cancelAdminEventModal}
        submitAdminEvent={_submitAdminEvent}
        parcelActions={PARCEL_ACTIONS}
        changeReason={_changeReason}
        changeReassignStatus={status => _changeData("newParcelStatus", status)}
        changeDetails={_changeDetails}
        changeData={_changeData}
        collectionCarriers={collectionCarriers}
        isParcelAlreadyCollected={isParcelAlreadyCollected}
        setIsParcelAlreadyCollected={setIsParcelAlreadyCollected}
      />
      <AdminReassignModal
        reassignModalData={adminReassignModal}
        cancelAdminReassignModal={_cancelAdminReassignModal}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        confirmAdminReassignModal={(addressId: string, newKeeperInfos: any) => {
          _changeData("addressId", addressId)
          setAdminReassignModal(state => ({ ...state, newKeeperInfos: newKeeperInfos }))
          _openAdminEventModal(AdminParcelEventType.REASSIGNMENT)
        }}
        keepersToReassign={dataReassign.filter(el => el?.node?.address?.id !== address?.id)}
        pageInfos={pageInfos}
        loading={loadingReassign}
      />
      <AdminRequestModal
        isOpened={isReturRequestModalOpened}
        onClose={() => setIsReturRequestModalOpened(false)}
        requestDetails={parcelReturnRequestData?.getReturnRequestDetails}
        onSuccess={getParcelInfo}
      />
      <ReturnToPlanModal
        isOpened={isReturnToPlanOpened}
        openReturnToPlanModal={_openReturnToPlanModal}
        cancelReturnToPlanModal={_cancelReturnToPlanModal}
        parcelStatus={parcel.status}
        submitAdminEvent={_submitAdminEvent}
      />
    </Fragment>
  )
}

export default ParcelHeader
