import React, { ReactElement, useEffect } from "react"
import Title from "components/Title"
import { Subtitle } from "components/EventPopups/EventPopups.styled"
import { PageWrapper } from "components/Layout/Structure"
// import { RequestedTransfers } from "components/WireTransfers/RequestedTranfers/RequestedTransfers"
// import { PerformedTransfersHistory } from "components/WireTransfers/PerformedTransfersHistory/PerformedTransfersHistory"
import { useDispatch } from "react-redux"
import { apiGetAllPayments, resetPayments } from "services/payments"
import { WIRE_TRANSFERS_LIMIT } from "components/WireTransfers/data/constants"

export const WireTransfers = (): ReactElement => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(resetPayments())
    dispatch(apiGetAllPayments({ limit: WIRE_TRANSFERS_LIMIT }))
  }, [])

  return (
    <PageWrapper>
      <Title>💰 Versements Keepers</Title>
      <Subtitle>Feature non activée</Subtitle>
      {/* <Subtitle>Liste des versements déjà effectués et de l’encours</Subtitle> */}

      {/* <RequestedTransfers /> */}
      {/* <PerformedTransfersHistory /> */}
    </PageWrapper>
  )
}
